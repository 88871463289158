export default function Wheel(){
    return (
        <div id="wheel-wrapper">
            <div className="wheel" id="wheel">
                <div className="center"></div>
                <div className="faixa">
                    <span>Alexa Echo Pop</span>
                </div>
                <div className="faixa">
                    <span>JBL Go 3</span>
                </div>
                <div className="faixa">
                    <span>10% de desconto na mensalidade</span>
                </div>
                <div className="faixa">
                    <span>20% de desconto na mensalidade</span>
                </div>
                <div className="faixa">
                    <span>Instalação grátis</span>
                </div>
                <div className="faixa">
                    <span>1 ano de TV grátis</span>
                </div>
                <div className="faixa">
                    <span>6 meses de TV grátis</span>
                </div>
                <div className="faixa">
                    <div>
                        <span>Não foi dessa vez</span>
                    </div>
                </div>
            </div>
            <div id="seta"></div>
        </div>
    );
}
