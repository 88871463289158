import axios from "axios";

const api=axios.create({
  baseURL: "https://wsp.leadmi.pixell.cc/api",
});

export const api2=axios.create({
  baseURL: "https://roleta.wsp.pixell.cc/api/",
});

export default api;
