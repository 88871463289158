import { useEffect, useState } from "react";
import api from '../services/api';
import InputMask from 'react-input-mask';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const MySwal = withReactContent(Swal);

export default function Form({rodando,setRodando,prize,numPremios,carregandoDadosIniciais,jaParticipou,name,setName,email,setEmail,telefone,setTelefone,isClient,setIsClient}){
    const [displayCpf,setDisplayCpf]=useState(false);
    useEffect(async ()=>{
        if(prize){
            try{
                let observation='';
                if(prize==='Não foi dessa vez'){
                    observation=`Não ganhou prêmio`;
                }else{
                    observation=`Ganhou o prêmio: ${prize}`;
                }

                observation+=`. ${isClient=='sim'?'Já é cliente.':'Não é cliente.'}`;

                await api.post('/origem/e21b4e56-cc13-48bb-a787-30c486783522',{
                    nome_card: name,
                    observacoes: observation,
                    token: 'TK_EE32C34C6D004C83B673E554275FB42D_leadmi',
                    url_origem: window.location.href,
                    contatos: [
                        {
                            nome: name,
                            cargo: ' ',
                            email,
                            telefone: '55'+telefone.replace(/\D/g,''),
                        },
                    ],
                }).then((response)=>{
                    if(prize==='Não foi dessa vez'){
                        MySwal.fire({
                            title: 'Que pena',
                            html: `Infelizmente não foi dessa vez`,
                            icon: 'warning',
                            confirmButtonText: 'Ok',
                        });
                    }else{
                        MySwal.fire({
                            title: `Parabéns ${name}`,
                            html: `Em breve alguém da nossa equipe entrará em contato<br>Prêmio: <b>${prize}</b>`,
                            icon: 'success',
                            confirmButtonText: 'Ok',
                        });
                    }
                }).catch((error)=>{
                    MySwal.fire({
                        title: 'Erro!',
                        text: 'Por favor, tente novamente mais tarde',
                        icon: 'error',
                        confirmButtonText: 'Ok',
                    });
                });
                setName('');
                setEmail('');
                setTelefone('');
            }catch(error){
                console.log(error);
            }
        }
    },[prize]);

    function handleSubmit(e){
        e.preventDefault();
        if(!name){
            MySwal.fire({
                title: 'Atenção',
                text: 'É necessário informar o seu nome',
                icon: 'warning',
                toast: true,
                position: 'top',
                showConfirmButton: false,
                timer: 3000,
            });
            document.getElementById('name').focus();
            return;
        }
        if(!telefone){
            MySwal.fire({
                title: 'Atenção',
                text: 'É necessário informar o seu telefone',
                icon: 'warning',
                toast: true,
                position: 'top',
                showConfirmButton: false,
                timer: 3000,
            });
            document.getElementById('telefone').focus();
            return;
        }
        if(telefone.replace(/\D/g,'').length<10){
            MySwal.fire({
                title: 'Atuação',
                text: 'Telefone inválido',
                icon: 'warning',
                toast: true,
                position: 'top',
                showConfirmButton: false,
                timer: 3000,
            });
            document.getElementById('telefone').focus();
            return;
        }

        setRodando(true);
    }

    return (
        <>
            <h2>Chegou a hora de testar sua sorte!</h2>
            <p>Prencha o formulário e libere a roleta</p>
            {carregandoDadosIniciais?(
                <p>Por favor aguarde</p>
            ):(
                <>
                    {prize?(
                        <div style={{textAlign: 'center', color: '#FFF'}}>
                            {prize==='Não foi dessa vez'?(
                                <p>Infelizmente não foi dessa vez</p>
                            ):(
                                <>
                                    <p>Parabéns!</p>
                                    <p>O seu prêmio é: <strong>{prize}</strong></p>
                                </>
                            )}
                        </div>
                    ):(
                        <>
                            {numPremios===0?(
                                <p>Todos os prêmios já foram sorteados</p>
                            ):(
                                <>
                                    {jaParticipou?(
                                        <>
                                            <p>Você já participou do sorteio, em breve iremos entrar em contato.</p>
                                            <p>Muito obrigado</p>
                                        </>
                                    ):(
                                        <form className="form-roleta" onSubmit={handleSubmit}>
                                            <div className="form-group">
                                                <label>Nome</label>
                                                <input type="text" id="name" value={name} disabled={rodando} placeholder="Informe o seu nome *" title="Nome" onChange={e=>setName(e.target.value)} />
                                            </div>
                                            <div style={{width: '50%',display: 'inline-block'}}>
                                                <div className="form-group">
                                                    <label>Telefone</label>
                                                    <InputMask placeholder="Telefone *" disabled={rodando} title="Telefone" id="telefone" mask="(99) 99999 9999" maskChar=" " onChange={e=>setTelefone(e.target.value)} />
                                                </div>
                                            </div>
                                            <div style={{width: '50%',display: 'inline-block'}}>
                                                <div className="form-group">
                                                    <label>e-mail (opcional)</label>
                                                    <input type="email" id="email" value={email} disabled={rodando} placeholder="Qual o seu melhor e-mail?" title="E-mail (opcional)" onChange={e=>setEmail(e.target.value)} />
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label>É cliente?</label>
                                                <select id="cliente" value={isClient} disabled={rodando} onChange={e=>setIsClient(e.target.value)}>
                                                    <option value="nao" selected>Não</option>
                                                    <option value="sim">Sim</option>
                                                </select>
                                            </div>
                                            <div style={{padding: '0 15px'}}>
                                                {rodando?(
                                                    <button type="submit" disabled>
                                                        Aguarde...
                                                    </button>
                                                ):(
                                                    <button type="submit">
                                                        Girar a roleta
                                                    </button>
                                                )}
                                            </div>
                                        </form>
                                    )}
                                </>
                            )}
                        </>
                    )}
                </>
            )}
        </>
    );
}
